const NotFound = () => (
	<svg
		version="1.1"
		width="100%"
		viewBox="0 0 544 323"
		enableBackground="new 0 0 544 323"
	>
		<path
			fill="none"
			opacity="1.000000"
			stroke="none"
			d="
M396.000000,324.000000
C264.000031,324.000000 132.500076,324.000000 1.000088,324.000000
C1.000059,216.333405 1.000059,108.666809 1.000029,1.000160
C182.333206,1.000107 363.666412,1.000107 544.999756,1.000053
C544.999817,108.666512 544.999817,216.333023 544.999878,323.999756
C495.500000,324.000000 446.000000,324.000000 396.000000,324.000000
M147.130295,196.335861
C147.359344,195.221390 147.588394,194.106903 147.970840,192.554977
C147.970840,192.554977 148.037308,192.096207 148.600433,191.904602
C148.644211,191.366272 148.687988,190.827957 148.737549,190.126541
C148.737549,190.126541 148.809540,189.806656 149.209900,189.220749
C149.839142,187.492966 150.468399,185.765198 151.677658,183.818573
C151.818222,183.224533 151.958786,182.630478 152.748352,181.751602
C154.832886,178.445663 156.917419,175.139725 159.724045,172.218018
C160.485596,177.783401 161.269241,183.345856 162.002151,188.915009
C162.664902,193.951141 163.272217,198.994553 164.006653,204.689682
C164.063797,205.125717 164.120956,205.561737 164.017578,206.778366
C164.410416,208.465668 164.803268,210.152969 165.087906,212.186493
C164.999527,212.435623 165.031433,212.668259 165.022034,213.499268
C166.000992,215.034378 166.979950,216.569489 168.268036,218.795898
C173.765411,219.864761 179.261932,221.835800 184.760300,221.840866
C204.840118,221.859375 224.920532,221.222214 245.616837,220.985687
C246.039246,221.009857 246.461655,221.034027 247.145523,221.652634
C247.817551,222.098877 248.489578,222.545135 249.009476,223.918884
C248.974777,241.926483 248.940063,259.934082 248.984512,277.985474
C248.984512,277.985474 248.940842,277.906250 248.185272,277.991760
C242.132217,279.968475 236.627075,282.660431 233.258301,289.236664
C232.801727,290.469482 232.345169,291.702332 230.982803,293.000275
C215.166046,294.338135 199.299606,295.272797 183.557053,297.212769
C177.281372,297.986115 170.252548,298.715210 165.147125,305.917572
C168.672226,307.392426 171.677322,309.085999 174.904572,309.929657
C197.071396,315.724213 219.925461,316.018402 242.529266,316.708679
C276.570374,317.748291 310.698212,317.763367 344.659912,313.971985
C352.567505,313.089203 360.556274,312.476593 368.303375,310.797058
C372.472534,309.893188 377.909241,310.063934 380.388855,303.864563
C377.785461,302.535583 375.387756,300.610321 372.710846,300.071045
C363.068542,298.128632 353.370941,296.349152 343.618011,295.105804
C336.776306,294.233612 329.796234,294.508392 322.905518,293.937683
C318.255432,293.552551 313.653412,292.587067 308.797363,291.229340
C307.481903,289.015594 306.166443,286.801849 304.717987,284.287567
C304.717987,284.287567 304.418793,284.151489 304.062042,283.618225
C300.731201,281.403015 297.400360,279.187836 293.960571,276.049561
C293.989380,271.371368 294.018188,266.693207 294.812805,261.597290
C294.911804,248.084076 295.010834,234.570847 296.025299,220.986664
C302.258911,221.322601 308.495361,221.987427 314.725616,221.933853
C334.749237,221.761673 354.774811,221.456482 374.787445,220.820160
C377.264709,220.741409 379.668671,218.356384 382.768677,216.762527
C383.264160,215.603973 384.068512,214.480804 384.205963,213.281235
C385.245209,204.209915 386.153595,195.123596 387.827240,185.660370
C387.958954,183.231567 388.090668,180.802750 388.289368,177.138214
C390.024750,180.206589 391.099091,182.106201 392.066345,184.536789
C392.361847,184.719864 392.657318,184.902939 392.989014,185.012344
C392.989014,185.012344 392.916443,185.050705 392.997925,185.811539
C393.968140,188.219879 394.938354,190.628220 396.011597,193.689087
C396.067902,194.123779 396.124237,194.558456 396.018677,195.609497
C396.314575,196.085648 396.610504,196.561783 397.011810,197.774719
C397.067139,198.514206 397.122437,199.253693 397.013947,200.729721
C397.309753,201.833160 397.605560,202.936600 398.005310,204.776718
C398.061005,205.515778 398.116669,206.254852 398.012024,207.681686
C398.308044,208.467957 398.604065,209.254227 399.007568,210.827988
C399.065857,211.882996 399.124115,212.937988 399.019226,214.630402
C398.868256,215.472076 398.717285,216.313751 398.265259,217.299286
C398.265259,217.299286 398.139160,217.608154 397.582520,217.969238
C395.188385,222.355423 391.184692,226.319672 393.999664,232.825546
C394.666809,238.131180 398.054810,241.874588 402.302094,241.998901
C407.171509,242.141434 411.076843,238.569199 412.810944,232.672195
C412.572266,230.435196 412.333588,228.198196 411.992950,225.181213
C412.208649,220.878998 409.634735,218.575943 405.806549,216.264862
C405.235687,214.496918 404.664795,212.728973 403.989014,210.138748
C403.934906,208.761383 403.880798,207.384018 403.987427,205.317337
C403.688568,204.532623 403.389679,203.747910 402.983704,202.226349
C402.928253,201.486557 402.872833,200.746780 402.983765,199.320114
C402.687775,198.533615 402.391815,197.747116 401.991119,196.223953
C401.934967,195.484894 401.878784,194.745850 401.981812,193.390411
C401.686493,192.913788 401.391205,192.437149 400.991364,191.309143
C400.935150,190.875031 400.878937,190.440918 400.983704,189.390961
C400.689301,188.913574 400.394897,188.436188 399.989960,187.195084
C396.033508,178.451111 392.077057,169.707123 388.008484,160.117905
C388.672058,156.723373 389.450806,153.345810 389.954346,149.927689
C390.238525,147.998688 390.069855,146.002975 390.854980,143.618912
C390.841949,140.078079 390.828888,136.537231 390.966125,132.255966
C390.913147,131.505875 390.860168,130.755783 390.971313,129.360779
C391.011993,128.919815 391.052673,128.478851 391.830109,127.639137
C391.827209,125.090942 391.824310,122.542747 391.973450,119.254799
C391.920380,118.505035 391.867310,117.755272 391.991089,116.171669
C392.267242,112.445633 392.543427,108.719597 392.966400,104.152298
C393.009949,102.446419 393.053497,100.740532 393.834320,98.637596
C393.832550,96.089684 393.830780,93.541771 393.975159,90.177116
C394.016937,88.796692 394.058685,87.416267 394.803802,85.686371
C394.813385,84.122314 394.822968,82.558266 394.980988,80.107964
C395.021271,77.417419 395.061523,74.726883 395.828674,71.651169
C395.713654,68.163719 397.283234,64.196617 392.864441,61.337456
C392.519653,60.892952 392.174866,60.448448 391.717010,59.239307
C388.935333,58.493752 386.155090,57.105732 383.371735,57.099426
C344.246826,57.010838 305.121490,57.110432 265.186554,57.003471
C264.452728,54.774872 263.223236,52.576660 263.084320,50.311588
C262.756104,44.957966 260.458374,41.972279 254.600922,40.139469
C233.564896,40.094658 212.528885,40.039322 191.492859,40.043415
C190.649353,40.043579 189.805954,40.606991 188.267670,41.005882
C187.164627,41.636154 186.061600,42.266430 184.336441,43.135422
C183.854340,44.075916 183.372253,45.016415 182.222046,46.280434
C182.111053,47.506760 182.000061,48.733082 181.163742,50.351307
C181.167572,52.568840 181.171402,54.786373 181.026871,57.786354
C180.986053,58.845970 180.945251,59.905586 180.201401,61.314312
C180.190872,62.877613 180.180328,64.440918 180.020706,66.662460
C180.070541,67.106827 180.120377,67.551193 180.013611,68.776215
C179.731171,70.519684 179.448730,72.263153 179.014542,74.770576
C178.994003,75.808647 178.973465,76.846725 178.291580,78.168335
C177.196732,78.500038 176.101898,78.831741 174.318237,79.006409
C173.531342,79.303177 172.744431,79.599945 171.222458,80.006050
C170.484131,80.062836 169.745819,80.119614 168.133804,80.011658
C163.951309,81.052673 159.768814,82.093689 155.260178,83.276375
C155.260178,83.276375 155.126572,83.605888 154.619186,83.930321
C154.135635,84.956207 153.652100,85.982101 153.024857,87.664757
C153.075546,88.107315 153.126221,88.549881 153.017822,89.874695
C153.317032,92.928024 153.616241,95.981361 154.015076,99.823822
C154.069061,100.879921 154.123062,101.936028 154.013779,103.821068
C154.312561,105.893028 154.611359,107.964996 155.012589,110.774315
C155.069824,111.513702 155.127075,112.253082 155.019836,113.859741
C155.316513,116.585190 155.613190,119.310646 156.011810,122.824951
C156.067657,123.880867 156.123489,124.936790 156.017227,126.796509
C156.316422,128.542542 156.615631,130.288574 157.014404,132.772781
C157.070236,133.512695 157.126083,134.252625 157.017014,135.820984
C157.312241,137.893692 157.607452,139.966415 158.008667,142.775421
C158.065628,143.514420 158.122604,144.253403 158.014755,145.820969
C158.311630,147.893555 158.608505,149.966125 159.009186,152.827744
C159.065765,153.884125 159.122345,154.940521 159.018036,156.815811
C158.581970,158.823013 158.791992,161.398666 157.606293,162.746307
C149.119705,172.391968 143.325470,183.271164 141.027191,196.647827
C140.986420,197.086685 140.945663,197.525543 140.166702,198.369247
C139.864105,204.637283 140.231735,211.088928 134.260330,215.480896
C133.407913,216.107864 133.320267,217.774612 132.154327,219.363708
C132.401840,222.256561 132.649368,225.149414 133.003250,228.725113
C133.299576,229.497360 133.595917,230.269623 133.994720,231.793884
C135.097290,235.881149 137.942963,237.234375 141.929184,236.999771
C145.373016,236.797058 148.194672,235.838486 149.796616,231.694656
C152.803879,224.394943 152.121933,217.769669 146.457184,211.874939
C145.549393,210.930283 145.347595,209.307175 144.971863,207.267181
C145.012970,206.523270 145.054092,205.779343 145.741623,204.756104
C145.764679,203.836365 145.787750,202.916626 145.964493,201.350922
C146.006363,200.911118 146.048233,200.471329 146.740234,199.761734
C146.769165,198.842300 146.798111,197.922852 147.130295,196.335861
M404.241180,48.995865
C403.460815,49.984676 402.680450,50.973492 401.205322,52.321854
C400.919922,58.502716 403.484680,60.812813 409.086548,58.211277
C415.247803,55.349949 421.015503,51.534256 426.605530,47.633987
C427.815247,46.789940 428.247955,43.591190 427.714752,41.892570
C427.306335,40.591454 424.590912,38.929565 423.292053,39.213634
C420.385406,39.849339 417.722351,41.598942 414.231873,42.996086
C411.125305,44.978104 408.018738,46.960125 404.989899,48.986565
C404.989899,48.986565 404.944122,48.909649 404.241180,48.995865
M387.989563,30.769632
C387.657928,27.855814 387.342438,24.940042 386.990631,22.028667
C386.537140,18.275858 384.460052,15.914927 380.636810,16.090258
C377.047394,16.254866 375.904480,18.851601 375.985077,22.201786
C376.073151,25.864191 375.791016,29.546633 376.067932,33.190105
C376.323425,36.551346 376.950653,39.907658 377.695923,43.202400
C378.613678,47.259632 382.044373,45.994724 384.605103,45.901802
C387.350922,45.802158 388.294128,43.994961 388.058502,41.205193
C387.793060,38.062557 387.992401,34.880669 387.989563,30.769632
M400.445679,47.016586
C402.756866,41.392822 405.415070,35.878387 407.235504,30.100018
C408.171387,27.129261 410.265778,23.054493 405.323669,21.010445
C400.970886,19.210127 398.586395,21.985811 397.103119,25.274914
C394.803284,30.374590 393.070312,35.727577 391.015564,40.941059
C389.977966,43.573711 389.818298,46.511093 392.428680,47.633629
C394.531494,48.537899 397.389801,47.685417 400.445679,47.016586
z"
		/>
		<path
			fill="#E2EEFA"
			opacity="1.000000"
			stroke="none"
			d="
M155.184311,112.992470
C155.127075,112.253082 155.069824,111.513702 155.249817,110.152084
C155.050369,107.350609 154.613708,105.171364 154.177048,102.992126
C154.123062,101.936028 154.069061,100.879921 154.264404,99.152138
C154.068130,95.317780 153.622528,92.155106 153.176910,88.992439
C153.126221,88.549881 153.075546,88.107315 153.486023,87.314827
C154.340317,85.845230 154.733444,84.725563 155.126572,83.605888
C155.126572,83.605888 155.260178,83.276375 155.819122,83.426880
C160.587891,82.443718 164.797684,81.310051 169.007492,80.176392
C169.745819,80.119614 170.484131,80.062836 171.775909,80.210724
C173.221924,79.998085 174.114487,79.580765 175.007050,79.163445
C176.101898,78.831741 177.196732,78.500038 178.715927,78.418541
C183.365555,79.445793 187.573380,80.754288 191.819595,80.893578
C201.869965,81.223267 211.938568,80.997574 222.468475,80.997643
C236.290207,81.000877 249.643265,81.002884 263.034607,81.348495
C264.067780,82.980583 264.897675,84.451027 266.084900,85.527435
C272.844421,91.656067 277.228485,99.273895 280.340210,107.728493
C282.215942,112.824875 284.114288,117.912933 285.870789,123.305717
C285.169037,124.734818 284.598999,125.862930 283.740356,127.208351
C279.224060,135.102036 280.239868,140.426193 286.842102,146.374542
C284.786926,152.489227 282.889587,158.251709 280.570435,163.998962
C274.478363,163.530579 268.808136,163.077438 263.137939,162.624298
C263.143463,162.802429 263.149017,162.980545 263.154541,163.158676
C269.101410,164.094879 275.048248,165.031082 281.000427,166.222260
C281.008698,166.647217 281.011688,166.817200 280.737915,167.199844
C275.481415,178.090027 269.468567,187.918335 259.636688,195.017395
C256.700500,197.137451 254.523560,200.308975 251.679199,203.139374
C250.239761,203.520203 249.119888,203.760406 247.644791,204.039185
C245.192642,205.718277 243.095703,207.358826 240.749084,208.999481
C240.499390,208.999588 240.000000,209.000000 239.612061,209.000671
C237.149414,209.667557 235.074707,210.333771 232.625000,211.000000
C231.500015,211.000351 230.750031,211.000717 229.599136,211.053192
C220.198227,213.412460 211.133713,213.760376 201.624817,212.000946
C200.499893,212.000641 199.749954,212.000626 198.762909,211.729675
C188.906021,207.589417 179.321396,203.626862 169.622559,199.966965
C168.388290,199.501221 166.557098,200.617340 165.002136,201.375458
C165.002853,202.500000 165.003860,203.249695 164.729630,204.008179
C164.270920,204.022903 164.087448,204.028824 163.903976,204.034760
C163.272217,198.994553 162.664902,193.951141 162.002151,188.915009
C161.269241,183.345856 160.485596,177.783401 159.352844,171.679733
C158.117401,169.034729 157.232330,167.461731 159.064606,164.803787
C160.483139,162.746033 163.230637,158.924713 159.178925,155.996902
C159.122345,154.940521 159.065765,153.884125 159.246124,152.179901
C159.048569,149.352173 158.614059,147.172287 158.179565,144.992401
C158.122604,144.253403 158.065628,143.514420 158.244766,142.154160
C158.047882,139.352783 157.614899,137.172653 157.181915,134.992538
C157.126083,134.252625 157.070236,133.512695 157.248260,132.161026
C157.047852,129.697083 156.613602,127.844894 156.179337,125.992706
C156.123489,124.936790 156.067657,123.880867 156.257080,122.160355
C156.063004,118.661331 155.623657,115.826897 155.184311,112.992470
M247.472641,168.976974
C247.739624,168.859741 248.006607,168.742508 248.273590,168.625275
C248.189041,168.505051 248.104492,168.384827 248.019943,168.264603
C247.677902,168.507706 247.335876,168.750793 246.526245,169.022522
C246.259491,169.139130 245.992737,169.255722 245.725983,169.372330
C245.810242,169.492432 245.894501,169.612518 245.978760,169.732620
C246.320496,169.490341 246.662231,169.248047 247.472641,168.976974
M256.665649,164.999359
C257.433777,164.896347 258.201904,164.793350 258.970032,164.690338
C258.877991,164.420837 258.785980,164.151352 258.693970,163.881851
C257.796783,164.255417 256.899597,164.628998 255.417664,165.001068
C254.919998,165.096451 254.422333,165.191849 253.924652,165.287247
C254.010651,165.515976 254.096649,165.744720 254.182648,165.973450
C254.787918,165.648163 255.393173,165.322876 256.665649,164.999359
M229.136215,122.008621
C223.600021,121.097282 221.414520,124.567978 219.418655,129.518326
C214.089493,139.675278 223.620682,148.712906 231.991348,147.869385
C233.742035,147.692963 235.339233,145.993668 237.492981,144.783661
C237.951553,144.526459 238.410141,144.269257 239.584869,143.916962
C244.966248,138.248016 245.801971,131.638779 240.718719,127.074760
C237.891403,124.536270 233.590256,123.639305 229.136215,122.008621
M243.555893,170.788239
C242.104431,171.341736 240.652969,171.895233 239.201508,172.448746
C239.490021,172.914352 239.778549,173.379959 240.067062,173.845581
C241.221436,173.028793 242.375809,172.212021 243.555893,170.788239
M235.516312,176.419174
C235.516312,176.419174 235.577240,176.523010 235.516312,176.419174
z"
		/>
		<path
			fill="#E8F2FC"
			opacity="1.000000"
			stroke="none"
			d="
M280.992249,164.014191
C282.889587,158.251709 284.786926,152.489227 287.212708,146.443115
C292.369232,149.085144 296.831451,148.682495 301.130585,144.703064
C301.823303,143.965485 302.397003,143.486862 303.290710,142.851471
C304.622406,140.669235 306.090454,138.742584 306.554474,136.598618
C307.654572,131.515808 305.014465,127.740540 301.222046,124.788383
C298.509583,122.676903 295.559326,120.716812 291.580750,122.986252
C289.497528,122.960304 287.750000,122.982513 286.002472,123.004730
C284.114288,117.912933 282.215942,112.824875 280.340210,107.728493
C277.228485,99.273895 272.844421,91.656067 266.084900,85.527435
C264.897675,84.451027 264.067780,82.980583 263.285522,81.346504
C263.665497,81.000298 263.832733,80.999695 264.380768,81.006516
C267.174347,81.008987 269.587189,81.004036 272.468658,80.999405
C302.291534,81.000061 331.645782,81.000389 361.202972,81.270538
C366.106384,83.360054 368.484894,87.092148 368.886230,91.819695
C370.073242,105.801643 370.721954,119.833000 372.106201,133.792984
C372.845581,141.249680 374.666962,148.599106 375.860901,156.315582
C376.149078,158.754913 376.576355,160.875610 376.633667,163.076660
C375.911194,164.142929 375.178650,165.162979 375.263641,166.109634
C376.444733,179.265335 377.590302,192.427902 379.094299,205.548691
C379.540710,209.443069 381.072266,213.213058 382.106598,217.040054
C379.668671,218.356384 377.264709,220.741409 374.787445,220.820160
C354.774811,221.456482 334.749237,221.761673 314.725616,221.933853
C308.495361,221.987427 302.258911,221.322601 295.305023,220.978668
C294.408508,220.950256 294.232300,220.929840 294.031158,220.513809
C292.624664,216.285461 289.377686,218.661057 286.691010,217.870026
C283.558167,218.129822 280.781250,218.768860 277.979858,218.905167
C273.585938,219.118973 269.168945,218.871445 264.777100,219.106491
C263.807220,219.158417 262.901520,220.410065 261.645813,221.052689
C260.893555,221.024124 260.461761,221.052124 259.821167,220.788452
C254.659927,217.123474 251.442520,217.254211 247.721375,220.984116
C247.255707,221.019180 247.069885,221.038681 246.884064,221.058197
C246.461655,221.034027 246.039246,221.009857 245.194824,220.529724
C240.445786,219.715790 236.110107,219.438171 231.794785,218.972000
C228.857452,218.654678 225.948639,218.073380 222.138657,217.469284
C225.483185,214.717468 227.741623,212.859268 230.000046,211.001083
C230.750031,211.000717 231.500015,211.000351 232.867035,211.266449
C235.656052,210.688599 237.828033,209.844299 240.000000,209.000000
C240.000000,209.000000 240.499390,208.999588 241.104431,208.960968
C243.806320,207.281784 245.903152,205.641190 248.000000,204.000610
C249.119888,203.760406 250.239761,203.520203 252.031311,203.102417
C254.003738,202.238602 255.622513,201.841949 256.553864,200.819275
C262.812866,193.946426 273.093506,190.773178 275.483398,180.298203
C275.675201,179.457428 276.990875,178.925262 277.373077,178.066666
C278.999359,174.413300 280.470825,170.691025 282.317993,166.993317
C283.182831,166.772980 283.728424,166.553223 284.274048,166.333466
C283.186768,165.885193 282.099487,165.436935 281.007935,164.745026
C281.003632,164.501404 280.992249,164.014191 280.992249,164.014191
M291.755676,169.598480
C291.415741,169.612473 291.075806,169.626465 290.735870,169.640457
C291.155609,169.761932 291.575378,169.883408 292.066406,170.565720
C292.369446,170.883224 292.672455,171.200714 292.975494,171.518188
C293.153778,171.312546 293.332062,171.106903 293.510345,170.901260
C293.007843,170.599411 292.505371,170.297562 291.755676,169.598480
M298.436279,174.378510
C298.211456,174.927856 297.986633,175.477219 297.761841,176.026566
C298.324768,176.126129 298.887695,176.225677 299.450623,176.325241
C299.285217,175.763824 299.119812,175.202423 298.436279,174.378510
M288.515533,168.412628
C288.515533,168.412628 288.581329,168.522125 288.515533,168.412628
M303.576965,179.516205
C303.576965,179.516205 303.475677,179.574188 303.576965,179.516205
z"
		/>
		<path
			fill="#EFF5FC"
			opacity="1.000000"
			stroke="none"
			d="
M309.029907,291.881165
C313.653412,292.587067 318.255432,293.552551 322.905518,293.937683
C329.796234,294.508392 336.776306,294.233612 343.618011,295.105804
C353.370941,296.349152 363.068542,298.128632 372.710846,300.071045
C375.387756,300.610321 377.785461,302.535583 380.388855,303.864563
C377.909241,310.063934 372.472534,309.893188 368.303375,310.797058
C360.556274,312.476593 352.567505,313.089203 344.659912,313.971985
C310.698212,317.763367 276.570374,317.748291 242.529266,316.708679
C219.925461,316.018402 197.071396,315.724213 174.904572,309.929657
C171.677322,309.085999 168.672226,307.392426 165.147125,305.917572
C170.252548,298.715210 177.281372,297.986115 183.557053,297.212769
C199.299606,295.272797 215.166046,294.338135 231.724365,293.191101
C234.647766,301.351471 239.155060,304.263733 246.624283,301.025757
C251.217331,299.034576 254.926468,295.004456 259.496338,291.943604
C267.605164,291.982147 275.250153,291.967010 283.240723,291.984406
C284.046967,292.057678 284.507660,292.098419 285.011292,292.489197
C287.115631,298.170410 291.661499,300.650208 296.674774,301.825989
C303.877808,303.515320 307.833130,300.160278 308.248444,292.886047
C308.658508,292.460022 308.844208,292.170593 309.029907,291.881165
z"
		/>
		<path
			fill="#E8F2FC"
			opacity="1.000000"
			stroke="none"
			d="
M184.958572,42.896706
C186.061600,42.266430 187.164627,41.636154 188.905670,41.263401
C190.347733,41.679565 191.151611,41.975666 191.955902,41.976746
C211.769592,42.003300 231.583389,42.018002 251.396896,41.952190
C252.611771,41.948154 253.824371,41.263519 255.038040,40.895554
C260.458374,41.972279 262.756104,44.957966 263.084320,50.311588
C263.223236,52.576660 264.452728,54.774872 265.706177,57.463276
C267.753662,58.281979 269.278687,58.941135 270.809906,58.955940
C311.149567,59.345894 351.489868,59.669220 391.830078,60.003944
C392.174866,60.448448 392.519653,60.892952 392.952148,62.025753
C393.727173,65.821480 394.414490,68.928909 395.101807,72.036331
C395.061523,74.726883 395.021271,77.417419 394.564575,80.637077
C394.132233,82.789406 394.116333,84.412628 394.100433,86.035843
C394.058685,87.416267 394.016937,88.796692 393.539246,90.689377
C393.101227,93.812645 393.099121,96.423653 393.097046,99.034653
C393.053497,100.740532 393.009949,102.446419 392.526337,104.680367
C391.721344,107.805611 391.356384,110.402786 390.605560,112.942902
C389.480225,111.415947 388.278290,110.003250 388.088074,108.465416
C387.519226,103.866074 386.853882,99.188164 387.129730,94.595192
C387.589996,86.931793 384.745819,80.551857 380.985443,74.242035
C378.754822,70.499130 375.739166,70.012856 371.666687,71.000000
C370.888885,70.999901 370.444458,70.999794 369.926453,70.624786
C367.469177,69.499924 365.086853,68.100395 362.701508,68.095215
C306.302521,67.972534 249.903183,67.999443 193.503922,68.003441
C192.174057,68.003540 190.477905,67.548439 189.591660,68.189339
C187.825546,69.466515 186.504364,71.358963 184.680771,72.970757
C183.574570,72.629204 182.786972,72.314140 181.998825,71.634445
C181.388916,70.178398 180.779572,69.086983 180.170227,67.995560
C180.120377,67.551193 180.070541,67.106827 180.437256,66.247826
C180.870682,64.210526 180.887558,62.587864 180.904434,60.965202
C180.945251,59.905586 180.986053,58.845970 181.458832,57.295834
C181.890213,54.523346 181.889648,52.241375 181.889069,49.959408
C182.000061,48.733082 182.111053,47.506760 182.913666,46.073902
C184.056381,44.877151 184.507477,43.886929 184.958572,42.896706
z"
		/>
		<path
			fill="#EFF6FC"
			opacity="1.000000"
			stroke="none"
			d="
M361.000000,81.000717
C331.645782,81.000389 302.291534,81.000061 272.381042,80.622650
C269.031586,78.576324 266.293121,77.475388 264.000000,80.999084
C263.832733,80.999695 263.665497,81.000298 263.247284,81.002884
C249.643265,81.002884 236.290207,81.000877 222.370819,80.604927
C220.302948,79.807358 218.809372,79.094406 217.298462,79.055580
C211.813797,78.914635 206.317032,79.143631 200.839157,78.904694
C199.347015,78.839600 197.905136,77.622223 196.440186,76.933685
C197.949356,76.289261 199.467453,75.070282 200.966232,75.093582
C210.740341,75.245483 220.509659,75.932770 230.281647,75.954605
C276.017090,76.056831 321.752869,75.998131 367.488525,76.002007
C374.363525,76.002586 379.600433,80.995689 379.979279,87.958359
C380.087555,89.948296 380.136566,91.960625 379.968536,93.941971
C379.709839,96.992111 380.901855,98.710182 383.999268,99.333351
C383.999878,100.111168 383.999939,100.555634 383.779053,101.174988
C384.038361,101.899910 384.518555,102.449959 384.999176,103.375000
C384.999298,104.500000 384.999023,105.250000 384.605774,106.097519
C383.643738,114.266899 383.074707,122.338760 382.461792,131.033325
C385.176178,130.526581 386.588104,130.262985 388.350891,130.000107
C389.403595,130.002441 390.105408,130.004074 390.807190,130.005707
C390.860168,130.755783 390.913147,131.505875 390.518341,132.737732
C390.082092,136.825424 390.093628,140.431366 390.105194,144.037308
C390.069855,146.002975 390.238525,147.998688 389.954346,149.927689
C389.450806,153.345810 388.672058,156.723373 387.785889,160.549896
C387.377563,160.989151 387.191833,160.996414 386.629425,161.002411
C385.499359,161.002014 384.746002,161.002869 383.744446,161.003174
C383.496307,161.002609 383.000000,160.998779 382.993713,160.621643
C381.958496,158.025513 381.512848,155.006714 379.784729,153.746536
C375.605652,150.699066 375.880493,147.097733 376.461700,142.743729
C376.977264,138.881378 376.901001,134.822250 376.339966,130.958313
C375.151093,122.770378 373.154388,114.694305 372.102173,106.493683
C371.264069,99.961472 371.399170,93.308563 370.983917,86.716690
C370.617340,80.897263 366.950378,78.848083 361.000000,81.000717
z"
		/>
		<path
			fill="#EEF6FD"
			opacity="1.000000"
			stroke="none"
			d="
M383.998779,99.000000
C380.901855,98.710182 379.709839,96.992111 379.968536,93.941971
C380.136566,91.960625 380.087555,89.948296 379.979279,87.958359
C379.600433,80.995689 374.363525,76.002586 367.488525,76.002007
C321.752869,75.998131 276.017090,76.056831 230.281647,75.954605
C220.509659,75.932770 210.740341,75.245483 200.966232,75.093582
C199.467453,75.070282 197.949356,76.289261 196.440186,76.933685
C197.905136,77.622223 199.347015,78.839600 200.839157,78.904694
C206.317032,79.143631 211.813797,78.914635 217.298462,79.055580
C218.809372,79.094406 220.302948,79.807358 221.902145,80.603699
C211.938568,80.997574 201.869965,81.223267 191.819595,80.893578
C187.573380,80.754288 183.365555,79.445793 179.046600,78.276772
C178.973465,76.846725 178.994003,75.808647 179.387634,74.339363
C180.506943,73.271790 181.253159,72.635437 181.999390,71.999077
C182.786972,72.314140 183.574570,72.629204 185.014114,72.971519
C186.110519,72.998779 186.554947,72.998779 187.468109,72.999207
C249.291214,72.999451 310.645599,72.999268 372.398560,72.999435
C378.128815,72.769714 379.882843,76.783463 380.841461,80.687691
C382.314880,86.688431 382.986786,92.885971 383.998779,99.000000
z"
		/>
		<path
			fill="#CCDBE7"
			opacity="1.000000"
			stroke="none"
			d="
M259.032532,291.889893
C254.926468,295.004456 251.217331,299.034576 246.624283,301.025757
C239.155060,304.263733 234.647766,301.351471 232.177277,293.158569
C232.345169,291.702332 232.801727,290.469482 233.941559,289.099060
C239.341187,285.597717 244.062271,282.240509 248.756927,278.846741
C248.946045,278.710052 248.885223,278.227600 248.940857,277.906250
C248.940842,277.906250 248.984512,277.985474 249.346832,277.890778
C250.138702,276.815460 250.937973,275.836639 250.942688,274.854004
C251.020172,258.740845 251.035751,242.626892 250.918106,226.514191
C250.909500,225.335709 249.773468,224.165466 249.161606,222.991379
C248.489578,222.545135 247.817551,222.098877 247.014801,221.355408
C247.069885,221.038681 247.255707,221.019180 248.148239,220.994934
C251.244583,221.292770 253.634201,221.595352 256.015442,222.376953
C256.003510,234.215622 255.955475,245.575623 256.044403,256.934540
C256.057709,258.633514 256.713654,260.327454 257.022156,262.486633
C257.010864,267.955658 257.049713,272.961792 256.805328,278.200165
C257.374573,281.630371 258.227081,284.828308 259.028320,288.409973
C258.995544,289.825775 259.014038,290.857819 259.032532,291.889893
z"
		/>
		<path
			fill="#CCDBE7"
			opacity="1.000000"
			stroke="none"
			d="
M308.024048,293.022614
C307.833130,300.160278 303.877808,303.515320 296.674774,301.825989
C291.661499,300.650208 287.115631,298.170410 284.975891,292.068817
C284.615784,288.845184 283.857147,286.323730 284.195557,283.959259
C284.492859,281.881897 286.019012,279.980408 287.292816,277.701508
C287.717896,259.771393 287.878021,242.141052 287.908813,224.510468
C287.910278,223.669434 286.767578,222.826385 286.090332,221.763123
C286.022949,221.541977 285.971191,221.082520 286.096405,220.780029
C286.493073,219.659637 286.764496,218.841751 287.035950,218.023865
C289.377686,218.661057 292.624664,216.285461 293.734985,220.824097
C293.331055,234.471817 293.186096,247.413559 293.107941,260.355713
C293.104584,260.906921 293.719910,261.461823 294.047028,262.015015
C294.018188,266.693207 293.989380,271.371368 293.740875,276.726868
C293.895325,280.515991 300.742706,284.755585 304.418793,284.151489
C304.418793,284.151489 304.717987,284.287567 304.586670,284.768555
C305.644897,287.840576 306.834473,290.431580 308.024048,293.022614
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M383.000000,160.998779
C383.000000,160.998779 383.496307,161.002609 383.877991,161.299377
C384.790009,162.299927 385.320312,163.003738 386.330261,164.344025
C386.660919,162.709778 386.833496,161.856720 387.006104,161.003662
C387.191833,160.996414 387.377563,160.989151 387.841949,160.972504
C392.077057,169.707123 396.033508,178.451111 399.812073,187.730865
C400.030396,188.846695 400.426575,189.426743 400.822754,190.006790
C400.878937,190.440918 400.935150,190.875031 400.810791,191.788971
C401.027710,192.848129 401.425171,193.427460 401.822632,194.006790
C401.878784,194.745850 401.934967,195.484894 401.785095,196.777451
C401.991852,198.222961 402.404633,199.114975 402.817383,200.006989
C402.872833,200.746780 402.928253,201.486557 402.778625,202.779938
C402.991272,204.224564 403.408966,205.115616 403.826691,206.006653
C403.880798,207.384018 403.934906,208.761383 403.750641,210.806412
C403.083984,213.912308 401.524292,216.884506 406.101562,216.960297
C409.634735,218.575943 412.208649,220.878998 411.753113,225.827621
C411.709106,228.661377 411.904907,230.848740 412.100708,233.036087
C411.076843,238.569199 407.171509,242.141434 402.302094,241.998901
C398.054810,241.874588 394.666809,238.131180 394.241089,232.173065
C395.682831,227.937881 396.946045,224.374237 398.041718,220.759827
C398.336121,219.788666 398.120605,218.662933 398.139160,217.608154
C398.139160,217.608154 398.265259,217.299286 398.732697,217.399017
C401.746887,216.317215 401.710571,215.148804 399.182373,213.992996
C399.124115,212.937988 399.065857,211.882996 399.212799,210.248718
C399.002808,208.777603 398.587585,207.885757 398.172363,206.993912
C398.116669,206.254852 398.061005,205.515778 398.223480,204.199127
C398.020355,202.412079 397.599060,201.202621 397.177734,199.993164
C397.122437,199.253693 397.067139,198.514206 397.191803,197.252625
C396.974731,196.151382 396.577667,195.572266 396.180573,194.993149
C396.124237,194.558456 396.067902,194.123779 396.220581,193.151947
C395.258514,190.093430 394.087463,187.572067 392.916443,185.050705
C392.916443,185.050705 392.989014,185.012344 392.905243,184.828461
C392.671478,184.364746 392.455475,184.151810 392.173462,184.005814
C391.099091,182.106201 390.024750,180.206589 388.289368,177.138214
C388.090668,180.802750 387.958954,183.231567 387.162415,185.570801
C386.353058,182.883972 385.488739,180.056366 386.229218,177.742310
C387.399689,174.084534 385.875793,171.689270 383.785065,169.392960
C381.699097,167.101868 379.277893,165.115982 377.003662,162.996292
C376.576355,160.875610 376.149078,158.754913 376.156921,156.463455
C378.727997,157.861389 380.863983,159.430084 383.000000,160.998779
z"
		/>
		<path
			fill="#EFF5FC"
			opacity="1.000000"
			stroke="none"
			d="
M376.633667,163.076660
C379.277893,165.115982 381.699097,167.101868 383.785065,169.392960
C385.875793,171.689270 387.399689,174.084534 386.229218,177.742310
C385.488739,180.056366 386.353058,182.883972 386.800232,185.761597
C386.153595,195.123596 385.245209,204.209915 384.205963,213.281235
C384.068512,214.480804 383.264160,215.603973 382.437622,216.901291
C381.072266,213.213058 379.540710,209.443069 379.094299,205.548691
C377.590302,192.427902 376.444733,179.265335 375.263641,166.109634
C375.178650,165.162979 375.911194,164.142929 376.633667,163.076660
z"
		/>
		<path
			fill="#E2EEFA"
			opacity="1.000000"
			stroke="none"
			d="
M148.037308,192.096207
C148.037308,192.096207 147.970840,192.554977 147.553162,192.858368
C146.755066,194.441544 146.374680,195.721344 145.748718,197.195847
C145.698807,198.270874 145.894455,199.151199 146.090103,200.031525
C146.048233,200.471329 146.006363,200.911118 145.575623,201.737152
C145.156235,203.094055 145.125717,204.064743 145.095200,205.035431
C145.054092,205.779343 145.012970,206.523270 144.580688,207.700928
C144.696243,210.458313 144.430237,213.626205 145.863235,214.937775
C149.329330,218.110168 148.524918,222.027603 148.883057,225.716522
C149.081635,227.761795 148.324112,229.899872 147.677948,232.162659
C143.032913,235.915634 139.774368,236.072159 136.545593,232.943192
C135.772781,232.194244 134.782501,231.669647 133.892242,231.041870
C133.595917,230.269623 133.299576,229.497360 133.251434,228.107498
C133.665802,224.660324 133.831985,221.830780 134.298828,218.851410
C141.237946,212.621857 143.641891,204.715820 140.904907,197.964401
C140.945663,197.525543 140.986420,197.086685 141.445648,196.243927
C142.917465,193.141815 143.837723,190.381683 145.057281,187.760818
C146.637405,184.365143 148.456070,181.080475 150.652847,176.814270
C151.395233,179.494461 151.747284,180.765457 152.099350,182.036438
C151.958786,182.630478 151.818222,183.224533 151.006134,183.985886
C149.826263,186.037689 149.317902,187.922165 148.809540,189.806656
C148.809540,189.806656 148.737549,190.126541 148.495865,190.636902
C148.181885,191.463577 148.109589,191.779892 148.037308,192.096207
z"
		/>
		<path
			fill="#E8F2FC"
			opacity="1.000000"
			stroke="none"
			d="
M199.000000,212.000610
C199.749954,212.000626 200.499893,212.000641 201.688354,212.355682
C203.900879,213.842010 205.674896,214.973328 207.448914,216.104630
C200.952408,217.821381 194.505417,218.909622 188.066772,218.862335
C181.374878,218.813156 174.691025,217.666245 167.992767,216.599838
C167.049118,215.095001 166.116348,213.989700 165.183594,212.884399
C165.031433,212.668259 164.999527,212.435623 165.370026,211.562897
C165.768112,209.292862 165.884048,207.646439 166.220856,205.825256
C165.962769,205.100128 165.483826,204.549759 165.004883,203.999390
C165.003860,203.249695 165.002853,202.500000 165.393433,201.383453
C168.390656,201.951172 171.947311,202.122009 173.425140,203.962250
C178.017441,209.680695 184.210953,210.366272 190.630035,211.024399
C193.424026,211.310852 196.210251,211.672897 199.000000,212.000610
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M168.003662,216.999390
C174.691025,217.666245 181.374878,218.813156 188.066772,218.862335
C194.505417,218.909622 200.952408,217.821381 207.448914,216.104630
C205.674896,214.973328 203.900879,213.842010 202.063324,212.355957
C211.133713,213.760376 220.198227,213.412460 229.599136,211.053192
C227.741623,212.859268 225.483185,214.717468 222.138657,217.469284
C225.948639,218.073380 228.857452,218.654678 231.794785,218.972000
C236.110107,219.438171 240.445786,219.715790 244.886765,220.452911
C224.920532,221.222214 204.840118,221.859375 184.760300,221.840866
C179.261932,221.835800 173.765411,219.864761 168.126801,218.174042
C167.991592,217.367920 167.997635,217.183655 168.003662,216.999390
z"
		/>
		<path
			fill="#E7EFFA"
			opacity="1.000000"
			stroke="none"
			d="
M387.989563,31.242744
C387.992401,34.880669 387.793060,38.062557 388.058502,41.205193
C388.294128,43.994961 387.350922,45.802158 384.605103,45.901802
C382.044373,45.994724 378.613678,47.259632 377.695923,43.202400
C376.950653,39.907658 376.323425,36.551346 376.067932,33.190105
C375.791016,29.546633 376.073151,25.864191 375.985077,22.201786
C375.904480,18.851601 377.047394,16.254866 380.636810,16.090258
C384.460052,15.914927 386.537140,18.275858 386.990631,22.028667
C387.342438,24.940042 387.657928,27.855814 387.989563,31.242744
z"
		/>
		<path
			fill="#E7EFFA"
			opacity="1.000000"
			stroke="none"
			d="
M400.179291,47.314468
C397.389801,47.685417 394.531494,48.537899 392.428680,47.633629
C389.818298,46.511093 389.977966,43.573711 391.015564,40.941059
C393.070312,35.727577 394.803284,30.374590 397.103119,25.274914
C398.586395,21.985811 400.970886,19.210127 405.323669,21.010445
C410.265778,23.054493 408.171387,27.129261 407.235504,30.100018
C405.415070,35.878387 402.756866,41.392822 400.179291,47.314468
z"
		/>
		<path
			fill="#E7EFFA"
			opacity="1.000000"
			stroke="none"
			d="
M414.960083,42.894882
C417.722351,41.598942 420.385406,39.849339 423.292053,39.213634
C424.590912,38.929565 427.306335,40.591454 427.714752,41.892570
C428.247955,43.591190 427.815247,46.789940 426.605530,47.633987
C421.015503,51.534256 415.247803,55.349949 409.086548,58.211277
C403.484680,60.812813 400.919922,58.502716 401.904297,52.117447
C403.383545,50.911919 404.163849,49.910793 404.944153,48.909657
C404.944122,48.909649 404.989899,48.986565 405.342651,48.979744
C408.783630,46.946911 411.871857,44.920898 414.960083,42.894882
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M391.773560,59.621628
C351.489868,59.669220 311.149567,59.345894 270.809906,58.955940
C269.278687,58.941135 267.753662,58.281979 266.111023,57.543701
C305.121490,57.110432 344.246826,57.010838 383.371735,57.099426
C386.155090,57.105732 388.935333,58.493752 391.773560,59.621628
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M152.423859,181.894012
C151.747284,180.765457 151.395233,179.494461 150.652847,176.814270
C148.456070,181.080475 146.637405,184.365143 145.057281,187.760818
C143.837723,190.381683 142.917465,193.141815 141.521896,195.921631
C143.325470,183.271164 149.119705,172.391968 157.606293,162.746307
C158.791992,161.398666 158.581970,158.823013 159.098480,156.406357
C163.230637,158.924713 160.483139,162.746033 159.064606,164.803787
C157.232330,167.461731 158.117401,169.034729 158.991791,171.487610
C156.917419,175.139725 154.832886,178.445663 152.423859,181.894012
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M254.819489,40.517509
C253.824371,41.263519 252.611771,41.948154 251.396896,41.952190
C231.583389,42.018002 211.769592,42.003300 191.955902,41.976746
C191.151611,41.975666 190.347733,41.679565 189.253082,41.214443
C189.805954,40.606991 190.649353,40.043579 191.492859,40.043415
C212.528885,40.039322 233.564896,40.094658 254.819489,40.517509
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M249.085541,223.455139
C249.773468,224.165466 250.909500,225.335709 250.918106,226.514191
C251.035751,242.626892 251.020172,258.740845 250.942688,274.854004
C250.937973,275.836639 250.138702,276.815460 249.307251,277.868896
C248.940063,259.934082 248.974777,241.926483 249.085541,223.455139
z"
		/>
		<path
			fill="#BEC8D5"
			opacity="1.000000"
			stroke="none"
			d="
M294.429932,261.806152
C293.719910,261.461823 293.104584,260.906921 293.107941,260.355713
C293.186096,247.413559 293.331055,234.471817 293.759888,221.219696
C294.232300,220.929840 294.408508,220.950256 294.847290,221.014160
C295.010834,234.570847 294.911804,248.084076 294.429932,261.806152
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M147.997528,231.996124
C148.324112,229.899872 149.081635,227.761795 148.883057,225.716522
C148.524918,222.027603 149.329330,218.110168 145.863235,214.937775
C144.430237,213.626205 144.696243,210.458313 144.504486,208.066193
C145.347595,209.307175 145.549393,210.930283 146.457184,211.874939
C152.121933,217.769669 152.803879,224.394943 149.175751,231.854584
C148.369095,232.008392 148.183304,232.002258 147.997528,231.996124
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M140.535797,198.166824
C143.641891,204.715820 141.237946,212.621857 134.018951,218.841370
C133.251846,218.974167 133.065262,218.967163 132.878662,218.960144
C133.320267,217.774612 133.407913,216.107864 134.260330,215.480896
C140.231735,211.088928 139.864105,204.637283 140.535797,198.166824
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M248.563065,277.949005
C248.885223,278.227600 248.946045,278.710052 248.756927,278.846741
C244.062271,282.240509 239.341187,285.597717 234.251617,288.956177
C236.627075,282.660431 242.132217,279.968475 248.563065,277.949005
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M147.677948,232.162659
C148.183304,232.002258 148.369095,232.008392 148.833328,232.028809
C148.194672,235.838486 145.373016,236.797058 141.929184,236.999771
C137.942963,237.234375 135.097290,235.881149 133.943481,231.417877
C134.782501,231.669647 135.772781,232.194244 136.545593,232.943192
C139.774368,236.072159 143.032913,235.915634 147.677948,232.162659
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M304.240417,283.884857
C300.742706,284.755585 293.895325,280.515991 293.795349,277.188416
C297.400360,279.187836 300.731201,281.403015 304.240417,283.884857
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M168.570648,80.094025
C164.797684,81.310051 160.587891,82.443718 155.982208,83.356049
C159.768814,82.093689 163.951309,81.052673 168.570648,80.094025
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M397.860840,217.788696
C398.120605,218.662933 398.336121,219.788666 398.041718,220.759827
C396.946045,224.374237 395.682831,227.937881 394.187866,231.781998
C391.184692,226.319672 395.188385,222.355423 397.860840,217.788696
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M390.991455,112.999962
C391.356384,110.402786 391.721344,107.805611 392.452942,105.100998
C392.543427,108.719597 392.267242,112.445633 391.695465,116.586754
C391.399841,117.001839 390.985352,117.000343 390.989319,116.600288
C390.992645,115.133476 390.992035,114.066719 390.991455,112.999962
z"
		/>
		<path
			fill="#E7EFFA"
			opacity="1.000000"
			stroke="none"
			d="
M390.480103,143.828110
C390.093628,140.431366 390.082092,136.825424 390.443207,133.107941
C390.828888,136.537231 390.841949,140.078079 390.480103,143.828110
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M395.465240,71.843750
C394.414490,68.928909 393.727173,65.821480 393.043579,62.298294
C397.283234,64.196617 395.713654,68.163719 395.465240,71.843750
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M153.097382,89.433563
C153.622528,92.155106 154.068130,95.317780 154.214600,98.757568
C153.616241,95.981361 153.317032,92.928024 153.097382,89.433563
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M414.595978,42.945488
C411.871857,44.920898 408.783630,46.946911 405.303772,48.957531
C408.018738,46.960125 411.125305,44.978104 414.595978,42.945488
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M132.516495,219.161926
C133.065262,218.967163 133.251846,218.974167 133.718292,218.991180
C133.831985,221.830780 133.665802,224.660324 133.198242,227.766083
C132.649368,225.149414 132.401840,222.256561 132.516495,219.161926
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M155.102081,113.426102
C155.623657,115.826897 156.063004,118.661331 156.206100,121.765930
C155.613190,119.310646 155.316513,116.585190 155.102081,113.426102
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M308.248444,292.886047
C306.834473,290.431580 305.644897,287.840576 304.653137,284.918823
C306.166443,286.801849 307.481903,289.015594 308.913635,291.555237
C308.844208,292.170593 308.658508,292.460022 308.248444,292.886047
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M391.821411,119.994553
C391.824310,122.542747 391.827209,125.090942 391.227722,127.724548
C390.414856,127.539162 390.204376,127.268364 389.995361,126.560326
C389.997498,124.081863 389.998138,122.040634 390.303009,119.999542
C391.011963,119.997963 391.416718,119.996254 391.821411,119.994553
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M393.465698,98.836121
C393.099121,96.423653 393.101227,93.812645 393.466125,91.097748
C393.830780,93.541771 393.832550,96.089684 393.465698,98.836121
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M392.957184,185.431122
C394.087463,187.572067 395.258514,190.093430 396.169067,192.825668
C394.938354,190.628220 393.968140,188.219879 392.957184,185.431122
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M158.097168,145.406677
C158.614059,147.172287 159.048569,149.352173 159.194214,151.785385
C158.608505,149.966125 158.311630,147.893555 158.097168,145.406677
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M181.526398,50.155357
C181.889648,52.241375 181.890213,54.523346 181.532990,56.904610
C181.171402,54.786373 181.167572,52.568840 181.526398,50.155357
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M157.099457,135.406769
C157.614899,137.172653 158.047882,139.352783 158.191772,141.786011
C157.607452,139.966415 157.312241,137.893692 157.099457,135.406769
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M154.095413,103.406601
C154.613708,105.171364 155.050369,107.350609 155.198593,109.783401
C154.611359,107.964996 154.312561,105.893028 154.095413,103.406601
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M412.455811,232.854141
C411.904907,230.848740 411.709106,228.661377 411.804077,226.217621
C412.333588,228.198196 412.572266,230.435196 412.455811,232.854141
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M405.954041,216.612579
C401.524292,216.884506 403.083984,213.912308 403.803101,211.217545
C404.664795,212.728973 405.235687,214.496918 405.954041,216.612579
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M156.098282,126.394608
C156.613602,127.844894 157.047852,129.697083 157.198456,131.791931
C156.615631,130.288574 156.316422,128.542542 156.098282,126.394608
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M181.998825,71.634445
C181.253159,72.635437 180.506943,73.271790 179.463516,73.957382
C179.448730,72.263153 179.731171,70.519684 180.091919,68.385887
C180.779572,69.086983 181.388916,70.178398 181.998825,71.634445
z"
		/>
		<path
			fill="#E2EEFA"
			opacity="1.000000"
			stroke="none"
			d="
M167.992767,216.599854
C167.997635,217.183655 167.991592,217.367920 167.972229,217.828400
C166.979950,216.569489 166.000992,215.034378 165.102814,213.191833
C166.116348,213.989700 167.049118,215.095001 167.992767,216.599854
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M394.452118,85.861107
C394.116333,84.412628 394.132233,82.789406 394.490356,81.080200
C394.822968,82.558266 394.813385,84.122314 394.452118,85.861107
z"
		/>
		<path
			fill="#E2EEFA"
			opacity="1.000000"
			stroke="none"
			d="
M166.000000,206.000000
C165.884048,207.646439 165.768112,209.292862 165.424133,211.389786
C164.803268,210.152969 164.410416,208.465668 164.401489,206.388336
C165.190262,205.998871 165.595139,205.999435 166.000000,206.000000
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M180.552917,61.139755
C180.887558,62.587864 180.870682,64.210526 180.511795,65.918701
C180.180328,64.440918 180.190872,62.877613 180.552917,61.139755
z"
		/>
		<path
			fill="#E8F2FC"
			opacity="1.000000"
			stroke="none"
			d="
M391.897461,119.624672
C391.416718,119.996254 391.011963,119.997963 390.055206,119.796646
C389.997223,118.729187 390.491272,117.864761 390.985352,117.000336
C390.985352,117.000343 391.399841,117.001839 391.607025,117.003677
C391.867310,117.755272 391.920380,118.505035 391.897461,119.624672
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M149.009720,189.513702
C149.317902,187.922165 149.826263,186.037689 150.716125,184.095306
C150.468399,185.765198 149.839142,187.492966 149.009720,189.513702
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M397.095856,200.361450
C397.599060,201.202621 398.020355,202.412079 398.171509,203.830780
C397.605560,202.936600 397.309753,201.833160 397.095856,200.361450
z"
		/>
		<path
			fill="#E7EFFA"
			opacity="1.000000"
			stroke="none"
			d="
M389.993896,126.997559
C390.204376,127.268364 390.414856,127.539162 390.859344,127.923920
C391.052673,128.478851 391.011993,128.919815 390.889252,129.683243
C390.105408,130.004074 389.403595,130.002441 388.351135,129.625305
C388.000702,128.500168 388.000946,127.750542 388.333374,127.000572
C389.108307,126.999336 389.551117,126.998451 389.993896,126.997559
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M399.100800,214.311707
C401.710571,215.148804 401.746887,216.317215 398.883240,217.327087
C398.717285,216.313751 398.868256,215.472076 399.100800,214.311707
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M145.994278,197.001144
C146.374680,195.721344 146.755066,194.441544 147.476440,193.077087
C147.588394,194.106903 147.359344,195.221390 146.770477,196.669067
C146.410660,197.002258 145.994278,197.001144 145.994278,197.001144
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M404.592651,48.952766
C404.163849,49.910793 403.383545,50.911919 402.251648,51.937675
C402.680450,50.973492 403.460815,49.984676 404.592651,48.952766
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M154.872879,83.768105
C154.733444,84.725563 154.340317,85.845230 153.557861,86.986450
C153.652100,85.982101 154.135635,84.956207 154.872879,83.768105
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M174.662643,79.084930
C174.114487,79.580765 173.221924,79.998085 172.143463,80.156067
C172.744431,79.599945 173.531342,79.303177 174.662643,79.084930
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M398.092194,207.337799
C398.587585,207.885757 399.002808,208.777603 399.159058,209.854980
C398.604065,209.254227 398.308044,208.467957 398.092194,207.337799
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M166.220856,205.825256
C165.595139,205.999435 165.190262,205.998871 164.481750,205.998047
C164.120956,205.561737 164.063797,205.125717 163.955322,204.362213
C164.087448,204.028824 164.270920,204.022903 164.729630,204.008179
C165.483826,204.549759 165.962769,205.100128 166.220856,205.825256
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M403.907043,205.661987
C403.408966,205.115616 402.991272,204.224564 402.832153,203.148361
C403.389679,203.747910 403.688568,204.532623 403.907043,205.661987
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M145.418411,204.895767
C145.125717,204.064743 145.156235,203.094055 145.498779,202.060120
C145.787750,202.916626 145.764679,203.836365 145.418411,204.895767
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M402.900574,199.663544
C402.404633,199.114975 401.991852,198.222961 401.837463,197.145782
C402.391815,197.747116 402.687775,198.533615 402.900574,199.663544
z"
		/>
		<path
			fill="#E0EAF7"
			opacity="1.000000"
			stroke="none"
			d="
M145.748718,197.195847
C145.994278,197.001144 146.410660,197.002258 146.618851,197.002838
C146.798111,197.922852 146.769165,198.842300 146.415161,199.896637
C145.894455,199.151199 145.698807,198.270874 145.748718,197.195847
z"
		/>
		<path
			fill="#E2EDF8"
			opacity="1.000000"
			stroke="none"
			d="
M184.647507,43.016064
C184.507477,43.886929 184.056381,44.877151 183.247711,45.912140
C183.372253,45.016415 183.854340,44.075916 184.647507,43.016064
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M400.903229,189.698883
C400.426575,189.426743 400.030396,188.846695 399.867371,188.112732
C400.394897,188.436188 400.689301,188.913574 400.903229,189.698883
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M401.902222,193.698608
C401.425171,193.427460 401.027710,192.848129 400.863068,192.114655
C401.391205,192.437149 401.686493,192.913788 401.902222,193.698608
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M396.099609,195.301331
C396.577667,195.572266 396.974731,196.151382 397.139099,196.884216
C396.610504,196.561783 396.314575,196.085648 396.099609,195.301331
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M148.318878,192.000397
C148.109589,191.779892 148.181885,191.463577 148.492966,190.718445
C148.687988,190.827957 148.644211,191.366272 148.318878,192.000397
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M392.119904,184.271301
C392.455475,184.151810 392.671478,184.364746 392.887146,184.865295
C392.657318,184.902939 392.361847,184.719864 392.119904,184.271301
z"
		/>
		<path
			fill="#D0DCE8"
			opacity="1.000000"
			stroke="none"
			d="
M291.916443,123.034416
C295.559326,120.716812 298.509583,122.676903 301.222046,124.788383
C305.014465,127.740540 307.654572,131.515808 306.554474,136.598618
C306.090454,138.742584 304.622406,140.669235 303.286438,142.468018
C302.067932,138.368088 299.154877,138.667694 296.464020,139.484146
C295.482330,139.782013 294.936981,141.517975 294.192596,142.597870
C296.140289,143.394897 298.082306,144.206451 300.042053,144.972626
C300.321198,145.081772 300.686279,144.971008 301.011597,144.962036
C296.831451,148.682495 292.369232,149.085144 287.370483,146.090881
C280.239868,140.426193 279.224060,135.102036 283.734589,127.642868
C284.265289,130.126053 284.062714,132.578476 284.899719,134.600616
C285.603210,136.300232 287.469025,138.549377 288.909271,138.615448
C290.272919,138.677994 292.886932,136.278351 292.963074,134.855240
C293.172211,130.944809 292.337860,126.978577 291.916443,123.034416
z"
		/>
		<path
			fill="#D0DCE8"
			opacity="1.000000"
			stroke="none"
			d="
M237.007156,144.996155
C235.339233,145.993668 233.742035,147.692963 231.991348,147.869385
C223.620682,148.712906 214.089493,139.675278 219.705399,129.656708
C220.194122,130.923599 220.199478,132.135056 220.634186,133.165375
C222.053528,136.529465 224.104248,139.622208 228.297806,138.656387
C232.725998,137.636520 234.335052,133.803802 233.767334,129.826416
C233.377136,127.092682 231.284836,124.601898 229.953339,122.002525
C233.590256,123.639305 237.891403,124.536270 240.718719,127.074760
C245.801971,131.638779 244.966248,138.248016 239.266479,143.570587
C238.126617,141.864685 237.305130,140.505157 236.483643,139.145630
C235.700333,139.863571 234.133484,140.965240 234.265549,141.234940
C234.937531,142.607117 236.049332,143.763931 237.007156,144.996155
z"
		/>
		<path
			fill="#FAFDFE"
			opacity="1.000000"
			stroke="none"
			d="
M229.544769,122.005569
C231.284836,124.601898 233.377136,127.092682 233.767334,129.826416
C234.335052,133.803802 232.725998,137.636520 228.297806,138.656387
C224.104248,139.622208 222.053528,136.529465 220.634186,133.165375
C220.199478,132.135056 220.194122,130.923599 219.983429,129.404495
C221.414520,124.567978 223.600021,121.097282 229.544769,122.005569
z"
		/>
		<path
			fill="#FAFDFE"
			opacity="1.000000"
			stroke="none"
			d="
M291.580750,122.986252
C292.337860,126.978577 293.172211,130.944809 292.963074,134.855240
C292.886932,136.278351 290.272919,138.677994 288.909271,138.615448
C287.469025,138.549377 285.603210,136.300232 284.899719,134.600616
C284.062714,132.578476 284.265289,130.126053 284.023193,127.425552
C284.598999,125.862930 285.169037,124.734818 285.870789,123.305717
C287.750000,122.982513 289.497528,122.960304 291.580750,122.986252
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M281.998779,166.993896
C280.470825,170.691025 278.999359,174.413300 277.373077,178.066666
C276.990875,178.925262 275.675201,179.457428 275.483398,180.298203
C273.093506,190.773178 262.812866,193.946426 256.553864,200.819275
C255.622513,201.841949 254.003738,202.238602 252.350861,202.961823
C254.523560,200.308975 256.700500,197.137451 259.636688,195.017395
C269.468567,187.918335 275.481415,178.090027 280.983826,167.201370
C281.506531,166.990234 281.998779,166.993896 281.998779,166.993896
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M198.762909,211.729675
C196.210251,211.672897 193.424026,211.310852 190.630035,211.024399
C184.210953,210.366272 178.017441,209.680695 173.425140,203.962250
C171.947311,202.122009 168.390656,201.951172 165.393738,201.008606
C166.557098,200.617340 168.388290,199.501221 169.622559,199.966965
C179.321396,203.626862 188.906021,207.589417 198.762909,211.729675
z"
		/>
		<path
			fill="#FAFDFE"
			opacity="1.000000"
			stroke="none"
			d="
M237.250061,144.889893
C236.049332,143.763931 234.937531,142.607117 234.265549,141.234940
C234.133484,140.965240 235.700333,139.863571 236.483643,139.145630
C237.305130,140.505157 238.126617,141.864685 238.908401,143.618134
C238.410141,144.269257 237.951553,144.526459 237.250061,144.889893
z"
		/>
		<path
			fill="#D0DCE8"
			opacity="1.000000"
			stroke="none"
			d="
M280.570435,163.998962
C280.992249,164.014191 281.003632,164.501404 281.002136,164.989731
C281.000641,165.478058 280.995117,165.967270 280.995117,165.967285
C275.048248,165.031082 269.101410,164.094879 263.154541,163.158676
C263.149017,162.980545 263.143463,162.802429 263.137939,162.624298
C268.808136,163.077438 274.478363,163.530579 280.570435,163.998962
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M247.644791,204.039169
C245.903152,205.641190 243.806320,207.281784 241.354126,208.960876
C243.095703,207.358826 245.192642,205.718277 247.644791,204.039169
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M239.612061,209.000671
C237.828033,209.844299 235.656052,210.688599 233.242035,211.266449
C235.074707,210.333771 237.149414,209.667557 239.612061,209.000671
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M243.543030,171.091736
C242.375809,172.212021 241.221436,173.028793 240.067062,173.845581
C239.778549,173.379959 239.490021,172.914352 239.201508,172.448746
C240.652969,171.895233 242.104431,171.341736 243.543030,171.091736
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M281.000427,166.222244
C280.995117,165.967270 281.000641,165.478058 281.006409,165.233368
C282.099487,165.436935 283.186768,165.885193 284.274048,166.333466
C283.728424,166.553223 283.182831,166.772980 282.317993,166.993317
C281.998779,166.993896 281.506531,166.990234 281.260590,166.988708
C281.011688,166.817200 281.008698,166.647217 281.000427,166.222244
z"
		/>
		<path
			fill="#D0DCE8"
			opacity="1.000000"
			stroke="none"
			d="
M256.002441,165.002563
C256.899597,164.628998 257.796783,164.255417 258.693970,163.881851
C258.785980,164.151352 258.877991,164.420837 258.970032,164.690338
C258.201904,164.793350 257.433777,164.896347 256.332825,164.999680
C256.000000,165.000000 256.002441,165.002579 256.002441,165.002563
z"
		/>
		<path
			fill="#D0DCE8"
			opacity="1.000000"
			stroke="none"
			d="
M255.999207,164.998795
C255.393173,165.322876 254.787918,165.648163 254.182648,165.973450
C254.096649,165.744720 254.010651,165.515976 253.924652,165.287247
C254.422333,165.191849 254.919998,165.096451 255.710052,165.001831
C256.002441,165.002579 256.000000,165.000000 255.999207,164.998795
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M246.993835,168.993896
C247.335876,168.750793 247.677902,168.507706 248.019943,168.264603
C248.104492,168.384827 248.189041,168.505051 248.273590,168.625275
C248.006607,168.742508 247.739624,168.859741 247.236267,168.988434
C246.999893,168.999878 246.993835,168.993896 246.993835,168.993896
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M247.001923,169.002808
C246.662231,169.248047 246.320496,169.490341 245.978760,169.732620
C245.894501,169.612518 245.810242,169.492432 245.725983,169.372330
C245.992737,169.255722 246.259491,169.139130 246.760040,169.008209
C246.993835,168.993896 246.999893,168.999878 247.001923,169.002808
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M235.546783,176.471100
C235.577240,176.523010 235.516312,176.419174 235.546783,176.471100
z"
		/>
		<path
			fill="none"
			opacity="1.000000"
			stroke="none"
			d="
M285.971191,221.082520
C285.971191,221.082520 286.022949,221.541977 286.009369,222.226669
C286.047363,241.276352 286.098938,259.641327 285.999329,278.334381
C281.156708,282.268524 281.451050,286.982452 282.895172,291.951874
C275.250153,291.967010 267.605164,291.982147 259.496338,291.943604
C259.014038,290.857819 258.995544,289.825775 259.374756,288.250854
C258.877838,284.461304 257.983215,281.214630 257.088593,277.967926
C257.049713,272.961792 257.010864,267.955658 257.401001,262.271118
C257.819122,248.707535 257.808258,235.822327 257.931641,222.647552
C258.720551,221.932022 259.375244,221.506088 260.029938,221.080139
C260.461761,221.052124 260.893555,221.024124 261.844421,221.418060
C270.232697,221.587509 278.101959,221.335007 285.971191,221.082520
z"
		/>
		<path
			fill="#EEF6FD"
			opacity="1.000000"
			stroke="none"
			d="
M382.993713,160.621643
C380.863983,159.430084 378.727997,157.861389 376.296021,156.144821
C374.666962,148.599106 372.845581,141.249680 372.106201,133.792984
C370.721954,119.833000 370.073242,105.801643 368.886230,91.819695
C368.484894,87.092148 366.106384,83.360054 361.202972,81.270538
C366.950378,78.848083 370.617340,80.897263 370.983917,86.716690
C371.399170,93.308563 371.264069,99.961472 372.102173,106.493683
C373.154388,114.694305 375.151093,122.770378 376.339966,130.958313
C376.901001,134.822250 376.977264,138.881378 376.461700,142.743729
C375.880493,147.097733 375.605652,150.699066 379.784729,153.746536
C381.512848,155.006714 381.958496,158.025513 382.993713,160.621643
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M286.096405,220.780029
C278.101959,221.335007 270.232697,221.587509 262.164856,221.474640
C262.901520,220.410065 263.807220,219.158417 264.777100,219.106491
C269.168945,218.871445 273.585938,219.118973 277.979858,218.905167
C280.781250,218.768860 283.558167,218.129822 286.691010,217.870026
C286.764496,218.841751 286.493073,219.659637 286.096405,220.780029
z"
		/>
		<path
			fill="#FAFDFE"
			opacity="1.000000"
			stroke="none"
			d="
M301.130585,144.703064
C300.686279,144.971008 300.321198,145.081772 300.042053,144.972626
C298.082306,144.206451 296.140289,143.394897 294.192596,142.597870
C294.936981,141.517975 295.482330,139.782013 296.464020,139.484146
C299.154877,138.667694 302.067932,138.368088 302.966431,142.624817
C302.397003,143.486862 301.823303,143.965485 301.130585,144.703064
z"
		/>
		<path
			fill="#E7F1FC"
			opacity="1.000000"
			stroke="none"
			d="
M259.821167,220.788452
C259.375244,221.506088 258.720551,221.932022 257.710815,222.516022
C256.911804,222.415359 256.467834,222.156631 256.023834,221.897919
C253.634201,221.595352 251.244583,221.292770 248.428070,220.979370
C251.442520,217.254211 254.659927,217.123474 259.821167,220.788452
z"
		/>
		<path
			fill="#EEF6FD"
			opacity="1.000000"
			stroke="none"
			d="
M264.380768,81.006516
C266.293121,77.475388 269.031586,78.576324 271.912384,80.622322
C269.587189,81.004036 267.174347,81.008987 264.380768,81.006516
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M298.695343,174.509766
C299.119812,175.202423 299.285217,175.763824 299.450623,176.325226
C298.887695,176.225677 298.324768,176.126129 297.761841,176.026581
C297.986633,175.477219 298.211456,174.927856 298.695343,174.509766
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M292.002869,169.995712
C292.505371,170.297562 293.007843,170.599411 293.510345,170.901245
C293.332062,171.106903 293.153778,171.312546 292.975494,171.518188
C292.672455,171.200714 292.369446,170.883224 292.033203,170.282883
C292.000000,170.000046 292.002869,169.995712 292.002869,169.995712
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M291.997559,170.002472
C291.575378,169.883408 291.155609,169.761932 290.735870,169.640472
C291.075806,169.626465 291.415741,169.612473 291.879272,169.797089
C292.002869,169.995712 292.000000,170.000046 291.997559,170.002472
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M288.548431,168.467377
C288.581329,168.522125 288.515533,168.412628 288.548431,168.467377
z"
		/>
		<path
			fill="#DCE5F2"
			opacity="1.000000"
			stroke="none"
			d="
M303.526306,179.545197
C303.475677,179.574188 303.576965,179.516205 303.526306,179.545197
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M283.240723,291.984406
C281.451050,286.982452 281.156708,282.268524 286.212280,278.331238
C286.576447,278.000000 287.002441,278.001221 287.002441,278.001221
C286.019012,279.980408 284.492859,281.881897 284.195557,283.959259
C283.857147,286.323730 284.615784,288.845184 284.932983,291.718750
C284.507660,292.098419 284.046967,292.057678 283.240723,291.984406
z"
		/>
		<path
			fill="#E9F2FC"
			opacity="1.000000"
			stroke="none"
			d="
M186.999390,72.998779
C186.554947,72.998779 186.110519,72.998779 185.332733,72.998016
C186.504364,71.358963 187.825546,69.466515 189.591660,68.189339
C190.477905,67.548439 192.174057,68.003540 193.503922,68.003441
C249.903183,67.999443 306.302521,67.972534 362.701508,68.095215
C365.086853,68.100395 367.469177,69.499924 369.457794,70.624908
C356.918304,70.999954 344.773956,71.000000 332.629578,71.000000
C285.882080,71.000015 239.134567,70.977798 192.387329,71.084389
C190.589905,71.088493 188.795303,72.332520 186.999390,72.998779
z"
		/>
		<path
			fill="#EBF4FC"
			opacity="1.000000"
			stroke="none"
			d="
M187.468109,72.999207
C188.795303,72.332520 190.589905,71.088493 192.387329,71.084389
C239.134567,70.977798 285.882080,71.000015 332.629578,71.000000
C344.773956,71.000000 356.918304,70.999954 369.531342,70.999817
C370.444458,70.999794 370.888885,70.999901 371.666687,71.333191
C372.000000,72.110611 372.000000,72.554848 372.000000,72.999084
C310.645599,72.999268 249.291214,72.999451 187.468109,72.999207
z"
		/>
		<path
			fill="#E9F2FC"
			opacity="1.000000"
			stroke="none"
			d="
M372.398560,72.999435
C372.000000,72.554848 372.000000,72.110611 372.000000,71.333191
C375.739166,70.012856 378.754822,70.499130 380.985443,74.242035
C384.745819,80.551857 387.589996,86.931793 387.129730,94.595192
C386.853882,99.188164 387.519226,103.866074 388.088074,108.465416
C388.278290,110.003250 389.480225,111.415947 390.605560,112.942902
C390.992035,114.066719 390.992645,115.133476 390.989319,116.600281
C390.491272,117.864761 389.997223,118.729187 389.750977,119.796509
C389.998138,122.040634 389.997498,124.081863 389.995361,126.560326
C389.551117,126.998451 389.108307,126.999336 388.332764,126.568581
C386.999603,119.424622 385.999207,112.712311 384.998779,106.000000
C384.999023,105.250000 384.999298,104.500000 384.976135,103.078819
C384.635101,101.938461 384.317566,101.469276 384.000000,101.000099
C383.999939,100.555634 383.999878,100.111168 383.999268,99.333351
C382.986786,92.885971 382.314880,86.688431 380.841461,80.687691
C379.882843,76.783463 378.128815,72.769714 372.398560,72.999435
z"
		/>
		<path
			fill="#EEF6FD"
			opacity="1.000000"
			stroke="none"
			d="
M384.605774,106.097519
C385.999207,112.712311 386.999603,119.424622 388.000610,126.568924
C388.000946,127.750542 388.000702,128.500168 388.000244,129.624588
C386.588104,130.262985 385.176178,130.526581 382.461792,131.033325
C383.074707,122.338760 383.643738,114.266899 384.605774,106.097519
z"
		/>
		<path
			fill="#EEF6FD"
			opacity="1.000000"
			stroke="none"
			d="
M386.629425,161.002411
C386.833496,161.856720 386.660919,162.709778 386.330261,164.344025
C385.320312,163.003738 384.790009,162.299927 384.126160,161.299927
C384.746002,161.002869 385.499359,161.002014 386.629425,161.002411
z"
		/>
		<path
			fill="#EEF6FD"
			opacity="1.000000"
			stroke="none"
			d="
M383.779053,101.174988
C384.317566,101.469276 384.635101,101.938461 384.975708,102.703819
C384.518555,102.449959 384.038361,101.899910 383.779053,101.174988
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M256.015442,222.376953
C256.467834,222.156631 256.911804,222.415359 257.576599,222.805603
C257.808258,235.822327 257.819122,248.707535 257.451172,261.808228
C256.713654,260.327454 256.057709,258.633514 256.044403,256.934540
C255.955475,245.575623 256.003510,234.215622 256.015442,222.376953
z"
		/>
		<path
			fill="#C4CFDF"
			opacity="1.000000"
			stroke="none"
			d="
M256.805328,278.200165
C257.983215,281.214630 258.877838,284.461304 259.426025,287.867126
C258.227081,284.828308 257.374573,281.630371 256.805328,278.200165
z"
		/>
		<path
			fill="#BEC8D5"
			opacity="1.000000"
			stroke="none"
			d="
M287.292816,277.701508
C287.002441,278.001221 286.576447,278.000000 286.363464,278.003174
C286.098938,259.641327 286.047363,241.276352 286.076752,222.447815
C286.767578,222.826385 287.910278,223.669434 287.908813,224.510468
C287.878021,242.141052 287.717896,259.771393 287.292816,277.701508
z"
		/>
	</svg>
)

export default NotFound

